@use 'styles/variables' as vars;

// ------------- MIXINS ------------- //
@mixin transition($time, $property: all, $easing: ease-in) {
  transition: $property $time $easing;
}

// ------------- VARIABLES ------------- //
$parallax-offset: 30vh;
$content-offset: 40vh;
$transition-speed: 1.2s;
$slide-number: 13;

.header-container {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
}

.navbar-container {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 42px;
  visibility: hidden;

  &.show {
    animation: showNav 0.5s linear forwards;
  }

  &.hide {
    animation: hideNav 0.5s linear forwards;
  }
}

.mobile-navbar-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  margin-top: vars.$header-default-height;
  transition: 0.5s transform ease-in-out;

  &.hide {
    transform: translateX(-100%);
  }

  &.show {
    transform: translateX(0%);
  }
}

.sections-counter-container {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  display: flex;
  align-items: center;

  &.show {
    animation: showNav 0.5s linear forwards;
  }

  &.hide {
    animation: hideNav 0.5s linear forwards;
  }
}

.home-slide {
  slide-size: cover;
  slide-repeat: no-repeat;
  slide-position: center center;
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  height: 100vh + $parallax-offset;
  position: fixed;
  width: 100%;
  transform: translateY($parallax-offset);
  @include transition($transition-speed, all, cubic-bezier(0.22, 0.44, 0, 1));

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    slide-color: rgba(0, 0, 0, 0.3);
  }

  &:first-child {
    transform: translateY(-$parallax-offset / 2);

    .content-wrapper {
      transform: translateY($parallax-offset / 2);
    }
  }
}

.content-wrapper {
  height: 100vh;
  transform: translateY($content-offset);
  will-change: transform;
  backface-visibility: hidden;
  @include transition($transition-speed + 0.5, all, cubic-bezier(0.22, 0.44, 0, 1));
}

// ------------- SET TRANSFORM VALUES ------------- //

.home-slide.up-scroll {
  transform: translate3d(0, -$parallax-offset / 2, 0);

  .content-wrapper {
    transform: translateY($parallax-offset / 2);
  }

  + .home-slide {
    transform: translate3d(0, $parallax-offset, 0);

    .content-wrapper {
      transform: translateY($parallax-offset);
    }
  }
}

.home-slide.down-scroll {
  transform: translate3d(0, -(100vh + $parallax-offset), 0);

  .content-wrapper {
    transform: translateY($content-offset);
  }

  + .home-slide:not(.down-scroll) {
    transform: translate3d(0, -$parallax-offset / 2, 0);

    .content-wrapper {
      transform: translateY($parallax-offset / 2);
    }
  }
}

@keyframes hideNav {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes showNav {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: vars.$device-tablet) {
  .sections-counter-container {
    right: 0;
    left: 0;
    top: unset;
    bottom: 0;
    justify-content: center;
  }
}

@media (max-width: vars.$device-mobile-large),
  (max-height: vars.$device-mobile-large + 1) and (orientation: landscape) {
  .mobile-navbar-container {
    margin-top: vars.$header-smaller-height;
  }
}
