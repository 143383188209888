$color-brand-gold: #b78727;
$color-brand-gold-alpha-95: rgba(183, 135, 39, 0.95);
$color-brand-gold-alpha-40:rgba(183, 135, 39, 0.4);
$color-brand-gold-alpha-26:rgba(183, 135, 39, 0.26);
$color-brand-navy-blue: #011b60;
$color-brand-navy-blue-alpha-20: rgba(1, 27, 96, 0.2);
$color-bw-black: #1e2226;
$color-bw-white: #ffffff;
$color-bw-white-alpha-20: rgba(255, 255, 255, 0.2);
$color-bw-g1: #2b3036;
$color-bw-g3: #5e666f;
$color-bw-g6: #c3c7cd;
$color-bw-g9: #f3f5f9;

$color-background-mobile-nav-dark: rgba(255, 255, 255, 0.95);
$color-background-mobile-nav-light: rgba(16, 24, 46, 0.95);
