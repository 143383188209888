@use 'styles/variables' as vars;
@use 'styles/colors' as colors;

$shadow: 0 7px 14px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);

.button {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;

  a {
    height: 100%;
    text-decoration: none;
  }

  &__content {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 300px;
    letter-spacing: 0.06em;
    text-decoration: none;
    font-weight: vars.$font-weight-bold;
    outline: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .regular {
    font-size: 16px;
  }

  .big {
    font-size: 18px;
  }

  .filled {
    border: none;

    &.primary {
      background-color: vars.$color-background-primary;
      color: vars.$color-font-default;

      &:hover {
        background-color: vars.$color-background-primary-alpha-95;
        box-shadow: $shadow;
      }
    }
  }

  .hollow {
    box-sizing: border-box;
    border-width: 1.5px;
    border-style: solid;
    background: transparent;

    &.secondary {
      border-color: vars.$color-background-secondary;
      color: vars.$color-font-secondary;

      &:hover {
        background-color: vars.$color-background-secondary-alpha-20;
        box-shadow: $shadow;
      }
    }

    &.white {
      border-color: colors.$color-bw-white;
      color: vars.$color-font-default;

      &:hover {
        background-color: colors.$color-bw-white-alpha-20;
        box-shadow: $shadow;
      }
    }
  }
}

@media (max-width: vars.$device-mobile-large) {
  .button {
    .big {
      font-size: 14px;
    }
  }
}
