@use 'styles/variables' as vars;

.what-we-do {
  @include vars.page-size;
  display: flex;
  background: url('../../../assets/images/mini/spiral-dotted-min.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  &__content {
    @include vars.display-flex(column, flex-end, center);
    @include vars.content-default-size;
    color: vars.$color-font-default;

    .logo {
      max-height: 100px;
    }

    h3 {
      margin: -15px 0 0 0;
      font-size: 50px;
      font-weight: vars.$font-weight-light;
    }

    p {
      text-align: end;
      font-size: 28px;
      font-weight: vars.$font-weight-light;
      position: relative;
      margin: 15px 27px 0 0;

      &:after {
        content: '';
        position: absolute;
        height: 80%;
        width: 2px;
        top: 10%;
        bottom: 10%;
        right: -15px;
        background: vars.$color-background-primary;
      }
    }
  }
}

@media (max-width: vars.$device-tablet) {
  .what-we-do {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(16, 24, 46, 0.4);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 30%;
      background: linear-gradient(180deg, #0a1d50 18.15%, rgba(18, 37, 73, 0) 95.54%);
    }

    &__content {
      align-items: center;
      z-index: 1;

      h3 {
        margin-top: 0;
      }

      p {
        margin-right: 0;
        text-align: center;

        &:after {
          right: 30%;
          left: 30%;
          width: 40%;
        }
      }
    }
  }
}

@media (min-width: vars.$device-mobile-large + 1) and (max-width: vars.$device-tablet) {
  .what-we-do {
    background-position: 10%;

    &__content {
      @include vars.content-tablet-size;

      .logo {
        width: 80%;
      }

      h3 {
        font-size: 38px;
        text-align: center;
      }

      p {
        margin-top: 42px;

        &:after {
          height: 1.5px;
          top: -20px;
        }
      }
    }
  }
}

@media (max-width: vars.$device-mobile-large) {
  .what-we-do {
    background-position: 20%;

    &__content {
      @include vars.content-mobile-size;

      .logo {
        width: 90%;
      }

      h3 {
        font-size: 21px;
      }

      p {
        font-size: 18px;
        margin-top: 30px;

        &:after {
          height: 1px;
          top: -15px;
        }
      }
    }
  }
}

@media (max-height: vars.$device-mobile-large + 1) and (orientation: landscape) {
  .what-we-do {
    background-position: 20%;

    &__content {
      @include vars.content-mobile-size;
      align-items: center;

      .logo {
        width: 50%;
      }

      h3 {
        font-size: 21px;
        margin: 0;
      }

      p {
        font-size: 18px;
        margin: 30px 0 0 0;
        text-align: center;

        &:after {
          height: 1px;
          top: -15px;
          width: 100%;
        }
      }
    }
  }
}
