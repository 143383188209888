@use 'styles/variables' as vars;
$image-height: 830px;
$image-width: 929px;

.our-hearos {
  @include vars.page-size;
  @include vars.display-flex(row, center, center);
  background: vars.$color-background-default;
  color: vars.$color-font-contrast;

  .our-hearos__content {
    @include vars.display-flex(row, center, center);
    width: vars.$width-page-default;
    height: 85%;
    position: relative;
    gap: 2%;
  }

  .peoples {
    @include vars.display-flex(row, center, center);
    height: 100%;
  }

  .peoples-img {
    height: 100%;
    max-width: 100%;
  }

  .texts__content {
    @include vars.display-flex(column, flex-start, center);
  }

  h3 {
    font-size: 64px;
    line-height: 75px;
    font-weight: vars.$font-weight-thin;
    text-transform: uppercase;
    margin: 0;
  }

  h5 {
    font-size: 26px;
    font-weight: vars.$font-weight-light;
    margin: 0;
    opacity: 0.5;
  }

  .text-emphasis {
    font-weight: vars.$font-weight-bold;
  }

  @media (orientation: portrait) {
    .our-hearos__content {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: vars.$device-laptop) {
    .our-hearos__content {
      height: 70%;
    }

    h3 {
      font-size: 35px;
      line-height: 35px;
    }

    h5 {
      font-size: 16px;
      font-weight: vars.$font-weight-regular;
      opacity: unset;
    }

    @media (orientation: portrait) {
      .our-hearos__content {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;
        height: 80%;
        gap: 6%;
      }

      .peoples {
        height: 100%;
        width: 100%;
        position: relative;
      }

      .peoples-img {
        position: absolute;
        height: 100%;
        max-width: unset;
        left: 0;
        transform: translateX(0);
        // transition: left 0.5s, transform 0.5s;

        &.right {
          left: 100%;
          transform: translateX(-100%);
        }
      }

      .texts {
        @include vars.display-flex(row, center, center);
        width: 100%;
      }

      .texts__content {
        @include vars.display-flex(column-reverse, flex-start, center);
      }

      h3 {
        font-size: 35px;
        line-height: 35px;
      }

      h5 {
        font-size: 16px;
        font-weight: vars.$font-weight-regular;
        opacity: unset;
      }

      @media (min-width: vars.$device-tablet) {
        .peoples-img {
          position: unset;
          width: 100%;

          &.right {
            left: 0;
            transform: translateX(0);
          }
        }

        .texts__content {
          @include vars.display-flex(column-reverse, center, center);
        }

        h3 {
          font-size: 50px;
          line-height: 60px;
          font-weight: vars.$font-weight-thin;
          text-align: center;
        }

        h5 {
          font-size: 21px;
          font-weight: vars.$font-weight-light;
          opacity: unset;
        }

        .text-emphasis {
          font-size: 60px;
        }
      }
    }
  }
}
