@use 'styles/variables' as vars;
@use 'styles/colors' as colors;

.mobile-navbar {
  display: flex;
  width: 100%;
  height: 100%;

  .content {
    @include vars.display-flex(column, flex-start, space-between);
    width: 100%;
    margin: 30px 50px;
  }

  &.light {
    background-color: colors.$color-background-mobile-nav-light;
    color: vars.$color-font-default;
  }

  &.dark {
    background-color: colors.$color-background-mobile-nav-dark;
    color: vars.$color-font-contrast;
  }

  ul {
    @include vars.display-flex(column, flex-start, space-around);
    list-style-type: none;
    margin: 0 0 0 5px;
    height: 50%;
    padding-left: 0;

    li {
      a {
        font-size: 21px;
        font-weight: vars.$font-weight-light;
        text-decoration: none;

        &.light {
          color: vars.$color-font-default;
        }

        &.dark {
          color: vars.$color-font-contrast;
        }

        &.active {
          font-weight: vars.$font-weight-bold;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: 2px;
            height: 100%;
            left: -5px;
            background: vars.$color-background-primary;
          }
        }
      }
    }
  }

  .info {
    @include vars.display-flex(column, center, center);
    width: 100%;
    text-align: center;

    .lets-talk {
      width: 100%;

      .button__content {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    p {
      margin: 40px 0 0 0;
      font-size: 14px;
      font-weight: vars.$font-weight-regular;
    }
  }
}

@media (max-width: vars.$device-mobile-large) {
  .mobile-navbar {
    .content {
      margin: 25px 30px;
    }

    .info {
      .button__content {
        padding-top: 6px;
        padding-bottom: 6px;
      }

      p {
        font-size: 10px;
        font-weight: vars.$font-weight-medium;
        margin-top: 20px;
      }
    }
  }
}

@media (max-height: vars.$device-mobile-large + 1) and (orientation: landscape) {
  .mobile-navbar {
    display: block;
    position: absolute;

    .content {
      width: unset;
    }

    .info {
      p {
        margin-top: 5px;
      }
    }
  }
}
