@use 'styles/variables' as vars;

.services-offered {
  @include vars.page-size;
  background: url('../../../assets/images/mini/working-min.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  &__content {
    @include vars.display-flex(row, center, space-between);
    height: 100%;
    color: vars.$color-font-default;
    text-align: end;
    margin: 0 150px;

    img {
      width: 45%;
    }

    .descriptions {
      @include vars.display-flex(column, flex-end, center);
      width: 45%;

      h3 {
        background: vars.$color-background-primary;
        padding: 18px 28px;
        margin: 0;
        font-size: 40px;
        line-height: 50px;
        font-weight: vars.$font-weight-light;
        text-transform: uppercase;
      }

      p {
        font-size: 35px;
        margin: 28px 24px 0 0;
        font-weight: vars.$font-weight-light;
        width: 90%;
      }
    }
  }
}

@media (max-width: vars.$device-laptop) {
  .services-offered .services-offered__content {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .descriptions {
      align-items: center;

      p {
        font-weight: vars.$font-weight-regular;
      }
    }
  }
}

@media (min-width: vars.$device-tablet + 1) and (max-width: vars.$device-laptop) {
  .services-offered .services-offered__content {
    img {
      max-height: 55%;
      max-width: 80%;
      width: unset;
    }

    .descriptions {
      width: 100%;

      h3 {
        font-size: 25px;
        line-height: 29px;
        padding: 10px 15px;
        margin-top: 15px;
      }

      p {
        font-size: 18px;
        margin: 10px 0 0 0;
      }
    }
  }
}

@media (min-width: vars.$device-mobile-large + 1) and (max-width: vars.$device-tablet) {
  .services-offered .services-offered__content {
    margin: 0 45px;

    img {
      max-height: 55%;
      max-width: 80%;
      width: unset;
    }

    .descriptions {
      width: 90%;

      h3 {
        font-size: 38px;
        line-height: 42px;
        padding: 12px 24px;
      }

      p {
        font-size: 21px;
        margin: 23px 0 0 0;
        width: 95%;
      }
    }
  }
}

@media (max-width: vars.$device-mobile-large) {
  .services-offered .services-offered__content {
    margin: 0 15px;

    img {
      max-height: 45%;
      max-width: 100%;
      width: auto;
      height: auto;
    }

    .descriptions {
      width: 80%;

      h3 {
        font-size: 20px;
        line-height: 22px;
        padding: 8px 4px;
        margin-top: 30px;
      }

      p {
        width: 100%;
        font-size: 14px;
        margin: 15px 0 0 0;
      }
    }
  }
}

@media (max-width: vars.$device-mobile-small) {
  .services-offered .services-offered__content .descriptions {
    width: 95%;

    h3 {
      margin-top: 15px;
    }

    p {
      margin-top: 10px;
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 1) {
  .services-offered .services-offered__content {
    @include vars.display-flex(row, center, space-between);
    margin: 0 15px;

    img {
      max-height: 65%;
    }

    .descriptions {
      h3 {
        font-size: 20px;
        line-height: 22px;
        padding: 8px 4px;
        margin-top: 10px;
      }

      p {
        width: 100%;
        font-size: 14px;
        margin: 15px 0 0 0;
      }
    }
  }
}
