@use 'styles/variables' as vars;

.welcome {
  @include vars.page-size;
  display: flex;
  position: relative;
  background: url('../../../assets/images/mini/golden-waves-frame.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  z-index: -2;

  video {
    position: absolute;
    background-color: transparent;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    transform: translate(-50%, -50%);
  }

  &:before {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(8, 31, 89, 0.35), rgba(8, 31, 89, 0.35)),
      linear-gradient(0deg, rgba(72, 14, 45, 0.2), rgba(72, 14, 45, 0.2));
  }

  &__content {
    @include vars.display-flex(column, center, center);
    @include vars.content-default-size;
    z-index: 2;

    h3 {
      color: vars.$color-font-default;
      text-transform: uppercase;
      font-weight: vars.$font-weight-light;
      font-size: 50px;
      margin: 0;
    }

    .logo {
      max-height: 88px;
      margin-bottom: 55px;
    }
  }
}

@media (min-width: vars.$device-mobile-large + 1) and (max-width: vars.$device-tablet) {
  .welcome .welcome__content {
    @include vars.content-tablet-size;

    h3 {
      font-size: 28px;
    }

    .logo {
      width: 90%;
    }
  }
}

@media (max-width: vars.$device-mobile-large) {
  .welcome .welcome__content {
    @include vars.content-mobile-size;

    h3 {
      font-size: 20px;
    }

    .logo {
      width: 90%;
    }
  }
}

@media (max-height: vars.$device-mobile-large + 1) and (orientation: landscape) {
  .welcome .welcome__content {
    @include vars.content-mobile-size;

    h3 {
      font-size: 20px;
    }

    .logo {
      width: 60%;
    }
  }
}
