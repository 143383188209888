@use 'styles/variables' as vars;

.contact-info {
  @include vars.page-size;
  display: flex;
  position: relative;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }

  &:before {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(27, 32, 37, 0) -0.02%, rgba(5, 12, 20, 0.6) 112.28%),
      linear-gradient(180deg, rgba(12, 13, 37, 0.5) 0%, rgba(6, 7, 43, 0) 50.05%),
      linear-gradient(0deg, rgba(3, 24, 65, 0.1), rgba(3, 24, 65, 0.1)),
      linear-gradient(0deg, rgba(8, 16, 58, 0.5), rgba(8, 16, 58, 0.5)),
      linear-gradient(0deg, rgba(8, 31, 89, 0.35), rgba(8, 31, 89, 0.35)),
      linear-gradient(0deg, rgba(72, 14, 45, 0.2), rgba(72, 14, 45, 0.2));
  }

  &__content {
    @include vars.display-flex(column, center, center);
    width: 100%;
    z-index: 2;
    color: vars.$color-font-default;
    margin: vars.$header-default-height 150px;

    .rights-reserved {
      font-size: 9px;
    }

    .description {
      @include vars.display-flex(column, center, center);
      text-align-last: center;
      text-transform: uppercase;

      h3 {
        margin: 0;
      }

      .text {
        font-size: 38px;
        font-weight: vars.$font-weight-light;
      }

      .text-emphasis {
        font-size: 64px;
        font-weight: vars.$font-weight-bold;
      }
    }

    .contacts {
      @include vars.display-flex(column, center, space-between);
      height: 40%;

      .mail {
        display: flex;

        a {
          font-size: 38px;
          line-height: 26.4px;
          font-weight: vars.$font-weight-light;
          color: vars.$color-font-primary;
          text-decoration: none;
          margin-top: 30px;
          padding: 15px;
        }
      }

      .social-medias a img {
        width: 40px;
        height: 40px;
      }

      .find-us {
        @include vars.display-flex(row, center, center);

        .homes {
          @include vars.display-flex(column, flex-end, flex-end);
          position: relative;
          text-align: end;

          h6 {
            font-size: 16px;
            font-weight: vars.$font-weight-bold;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            margin: 0;
          }

          p {
            font-size: 21px;
            font-weight: vars.$font-weight-light;
            margin: 10px 0 0 0;

            .spacing {
              width: 10px;
              display: inline-block;
            }
          }

          &:after {
            content: '';
            position: absolute;
            width: 2px;
            height: 95%;
            right: -32px;
            background: vars.$color-background-primary;
          }
        }

        .contact-us {
          @include vars.display-flex(column, flex-start, flex-start);
          margin-left: 66px;

          h6 {
            font-size: 16px;
            font-weight: vars.$font-weight-bold;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            margin: 0;
          }

          p {
            font-size: 21px;
            font-weight: vars.$font-weight-light;
            margin: 10px 0 0 0;
          }
        }
      }
    }

    h5 {
      font-size: 12px;
      font-weight: vars.$font-weight-bold;
      opacity: 0.5;
      margin: 30px 0 0 0;
    }
  }
}

@media (min-width: vars.$device-tablet + 1) and (max-width: vars.$device-laptop) and (max-height: 800px) {
  .contact-info .contact-info__content .contacts {
    height: 55%;
  }
}

@media (max-width: vars.$device-laptop) {
  .contact-info .contact-info__content {
    text-align: center;
    margin: vars.$header-default-height 120px;

    .contacts .find-us {
      flex-direction: column;

      .homes {
        align-items: center;

        p {
          margin-top: 0;
        }

        &:after {
          width: 50px;
          height: 2px;
          right: unset;
          bottom: -16px;
        }
      }

      .contact-us {
        align-items: center;
        margin-left: 0;
        margin-top: 34px;

        p {
          margin-top: 0;
        }
      }
    }
  }
}

@media (min-width: vars.$device-mobile-large + 1) and (max-width: vars.$device-tablet) {
  .contact-info .contact-info__content {
    margin: vars.$header-default-height 0;

    .description {
      .text {
        font-size: 32px;
      }

      .text-emphasis {
        font-size: 54px;
      }
    }

    .contacts {
      height: 55%;

      .mail a {
        font-size: 32px;
        padding: 10px 20px;
      }

      .find-us {
        .homes:after {
          bottom: -16px;
        }

        .contact-us {
          margin-top: 34px;
        }
      }
    }
  }
}

@media (max-width: vars.$device-mobile-large) {
  .contact-info .contact-info__content {
    margin: vars.$header-smaller-height 15px;

    .description {
      .text {
        font-size: 22px;
      }

      .text-emphasis {
        font-size: 48px;
        line-height: 52px;
      }
    }

    .contacts {
      height: 60%;

      .mail a {
        font-size: 25px;
        margin-top: 20px;
        padding: 10px;
        word-break: break-word;
      }

      .find-us {
        .homes {
          h6 {
            font-size: 14px;
          }

          p {
            font-size: 14px;
            font-weight: vars.$font-weight-regular;
          }

          &:after {
            bottom: -20px;
          }
        }

        .contact-us {
          margin-top: 42px;

          h6 {
            font-size: 14px;
          }

          p {
            font-size: 14px;
            font-weight: vars.$font-weight-regular;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 1) {
  .contact-info .contact-info__content {
    margin: 60px 20px;

    .description {
      .text {
        font-size: 15px;
      }

      .text-emphasis {
        font-size: 26px;
        line-height: 40px;
      }
    }

    .contacts {
      height: 60%;

      .mail a {
        font-size: 20px;
        padding: 5px;
        margin-top: 0;
      }

      .find-us {
        @include vars.display-flex(row, center, center);

        .homes {
          @include vars.display-flex(column, flex-end, flex-end);

          &:after {
            width: 2px;
            height: 95%;
            right: -32px;
            bottom: 0;
          }
        }

        .homes h6:after {
          bottom: -15px;
        }

        p,
        h6 {
          font-size: 14px;
        }

        .contact-us {
          @include vars.display-flex(column, flex-start, flex-start);
          margin-top: 0;
          margin-left: 66px;
        }
      }
    }

    h5 {
      margin-top: 10px;
    }
  }
}
