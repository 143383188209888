@use 'styles/variables' as vars;

.capabilities {
  @include vars.display-flex(row, center, center);
  @include vars.page-size;
  background: vars.$color-background-default;
  color: vars.$color-font-contrast;

  &__content {
    @include vars.display-flex(column, center, flex-start);
    width: 100%;
    height: 100%;
  }

  &__header {
    @include vars.display-flex(column, center, center);
    background: url('../../../assets/images/mini/drawing-min.jpg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    width: 100%;
    height: 30vh;

    h3 {
      font-size: 70px;
      font-weight: vars.$font-weight-light;
      text-transform: uppercase;
      color: vars.$color-font-default;
      position: relative;
      margin: 50px 0 0 0;
    }

    &:after {
      content: '';
      height: 2px;
      width: 10%;
      background: vars.$color-background-primary;
    }
  }

  &__list {
    @include vars.display-flex(row, center, center);
    width: 90%;
    height: 70vh;
    margin-bottom: 50px;

    &__content {
      @include vars.display-flex(row, flex-start, space-evenly);
      width: 100%;

      .capability {
        width: 30%;

        .card {
          width: 100%;
          height: 100%;
          opacity: 0.3;
          transform: scale(0.55);
          cursor: pointer;
        }

        .dismissed {
          animation: hideCard 0.5s ease-out forwards;
        }

        .active {
          animation: showCard 1s ease-in forwards;
        }
      }
    }
  }
}

@keyframes showCard {
  100% {
    transform: scale(1);
    opacity: 1;
    z-index: 2;
  }
}

@keyframes hideCard {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0.3;
    transform: scale(0.55);
  }
}

@media (min-width: vars.$device-tablet + 1) and (max-width: vars.$device-laptop) {
  .capabilities .capabilities__list {
    width: 80%;
  }
}

@media (min-width: vars.$device-mobile-large + 151) and (max-width: vars.$device-laptop) {
  .capabilities {
    justify-content: center;

    &__header {
      h3 {
        font-size: 70px;
      }

      &:after {
        width: 30%;
      }
    }

    &__content {
      background-size: 100% 30%;
    }

    &__list {
      &__content {
        height: 100%;

        .capability {
          width: 70%;
        }
      }
    }
  }
}

@media (max-width: vars.$device-mobile-large + 150) {
  .capabilities {
    justify-content: center;

    &__content {
      background-size: 100% 25%;
    }

    &__header {
      h3 {
        font-size: 38px;
      }

      &:after {
        width: 30%;
      }
    }

    &__list {
      &__content {
        height: 100%;

        .capability {
          width: 75%;
        }
      }
    }
  }
}

@media (max-width: vars.$device-mobile-small) {
  .capabilities {
    &__header {
      h3 {
        font-size: 25px;
      }
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 1) {
  .capabilities {
    .capabilities__content {
      background-size: 100% 25%;
    }

    .capabilities__list {
      min-height: 60%;
      height: auto;
      width: 90%;

      .capability {
        width: 90%;
      }
    }

    &__header {
      h3 {
        font-size: 38px;
      }

      &:after {
        width: 40%;
      }
    }

    h5 {
      font-size: 20px;
      line-height: 25px;
    }

    h6 {
      font-size: 14px;
    }

    .field-description {
      img {
        width: 35px;
        height: 35px;
      }

      p {
        font-size: 16px;
      }
    }

    .capability-description {
      margin: 5px 15px 0 15px;

      .description {
        font-size: 16px;
      }

      .solution {
        font-size: 18px;
      }
    }
  }
}
