@use 'styles/variables' as vars;
@use 'styles/colors' as colors;

.mobile-nav-control {
  margin-right: 20px;
  background: transparent;
  height: 35px;
  width: 35px;
  cursor: pointer;
  position: relative;

  span.dark {
    background: colors.$color-bw-g1;

    &:before,
    &:after {
      background: colors.$color-bw-g1;
    }
  }

  span.light {
    background: vars.$color-background-default;

    &:before,
    &:after {
      background: vars.$color-background-default;
    }
  }

  span,
  span:before,
  span:after {
    content: '';
    cursor: pointer;
    border-radius: 1px;
    height: 1.2px;
    width: 35px;
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    transition: all 0.5s ease-in-out;
  }

  span:before {
    top: -10px;
  }

  span:after {
    top: 10px;
  }

  &.active span {
    background-color: transparent;
  }

  &.active span:before,
  &.active span:after {
    top: 0;
  }

  &.active span:before {
    transform: rotate(135deg);
  }

  &.active span:after {
    transform: rotate(-135deg);
  }
}
