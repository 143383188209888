@use 'styles/variables' as vars;
@use 'styles/colors' as colors;

.navbar {
  ul {
    @include vars.display-flex(row, center, center);
    list-style-type: none;
    margin: 0;

    li {
      margin-right: 3%;

      &:first-child {
        margin-left: 3%;
      }

      a {
        font-size: 12px;
        font-weight: vars.$font-weight-light;
        text-decoration: none;
        opacity: 0.7;

        &.light {
          animation: turnColorLight 0.5s linear forwards;
        }

        &.dark {
          animation: turnColorDark 0.5s linear forwards;
        }

        &.active {
          font-weight: vars.$font-weight-bold;
          position: relative;
          opacity: 1;

          &:after {
            content: '';
            position: absolute;
            width: 10px;
            height: 5px;
            bottom: -5px;
            left: 50%;
            transform: translateY(-50%);
            border-radius: 50px;
            background: vars.$color-background-primary;
          }
        }
      }
    }
  }
}

@keyframes turnColorLight {
  0% {
    color: colors.$color-bw-g3;
  }

  100% {
    color: vars.$color-font-default;
  }
}

@keyframes turnColorDark {
  0% {
    color: vars.$color-font-default;
  }

  100% {
    color: colors.$color-bw-g3;
  }
}
