@use 'styles/variables' as vars;

.differentials {
  @include vars.page-size;
  display: flex;
  background: url('../../../assets/images/mini/path-min.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  color: vars.$color-font-default;

  &__content {
    @include vars.display-flex(column, center, space-evenly);
    @include vars.content-default-size;

    h3 {
      font-size: 70px;
      font-weight: vars.$font-weight-light;
      text-transform: uppercase;
      margin: 0;
    }

    h4 {
      font-size: 50px;
      font-weight: vars.$font-weight-light;
      text-transform: uppercase;
      margin: 0;

      .spacing {
        width: 35px;
        display: inline-block;
      }
    }
  }

  &__list {
    @include vars.display-flex(row, flex-start, space-evenly);
    width: 100%;

    .differential {
      margin-right: 5%;
      white-space: pre-line;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: vars.$device-mobile-large + 1) and (max-width: vars.$device-tablet) {
  .differentials {
    &__content {
      @include vars.content-tablet-size;

      h3 {
        font-size: 38px;
      }

      h4 {
        font-size: 30px;

        .spacing {
          width: 25px;
        }
      }
    }

    &__list {
      @include vars.display-flex(row, center, space-evenly);
      flex-wrap: wrap;

      .differential {
        width: 45%;
        margin-right: 0;

        &:first-child {
          margin-right: 10%;
        }

        &:last-child {
          width: 75%;
        }
      }
    }
  }
}

@media (max-width: vars.$device-mobile-large) {
  .differentials {
    &__content {
      margin: vars.$header-smaller-height 0px;

      h3 {
        font-size: 30px;
      }

      h4 {
        font-size: 20px;

        .spacing {
          width: 15px;
        }
      }
    }

    &__list {
      width: 100%;
      min-height: 85%;
      height: auto;

      .differential {
        width: 70%;
        margin-right: 0;
      }
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 1) {
  .differentials {
    .differentials__content {
      margin: vars.$header-smaller-height 10px;

      h3 {
        font-size: 30px;
      }

      h4 {
        font-size: 18px;

        .spacing {
          width: 15px;
        }
      }

      .differentials__list {
        width: 100%;
        min-height: 80%;
      }
    }
  }
}
