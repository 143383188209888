@use 'styles/variables' as vars;

.capability_card {
  color: vars.$color-font-contrast;
  width: 100%;

  .field-description {
    display: flex;
    align-items: flex-end;

    img {
      width: 61px;
      height: 61px;
    }

    p {
      font-size: 21px;
      font-weight: vars.$font-weight-light;
      text-transform: uppercase;
      margin: 0 5px 0 0;
    }
  }

  h5 {
    font-size: 45px;
    line-height: 50px;
    font-weight: vars.$font-weight-bold;
    text-transform: uppercase;
    margin: 0;
  }

  h6 {
    font-size: 21px;
    font-weight: vars.$font-weight-light;
    text-transform: uppercase;
    margin: -5px 0 0 0;
  }

  .capability-description {
    margin: 20px 10px 0 15px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -10px;
      width: 2px;
      height: 100%;
      background: vars.$color-background-primary;
    }

    .description {
      font-size: 18px;
      font-weight: vars.$font-weight-light;
      margin: 0;
    }

    .solution {
      font-size: 21px;
      font-weight: vars.$font-weight-medium;
      margin: 16px 0 0 0;
    }
  }
}

@media (max-width: vars.$device-mobile-large + 150) {
  .capability_card {
    .field-description {
      margin-left: 5px;

      img {
        width: 45px;
        height: 45px;
      }

      p {
        font-size: 16px;
      }
    }

    h5 {
      font-size: 25px;
      line-height: 30px;
      margin-top: 0;
      margin-left: 5px;
    }

    h6 {
      font-size: 14px;
      margin-left: 5px;
    }

    .capability-description {
      margin-top: 16px;
      margin-right: 10px;

      &:before {
        width: 1px;
        left: -10px;
      }

      .description {
        font-size: 14px;
        font-weight: vars.$font-weight-regular;
      }

      .solution {
        font-size: 18px;
        margin-top: 10px;
        font-weight: vars.$font-weight-bold;
      }
    }
  }
}
