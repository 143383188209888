@use 'styles/variables' as vars;

.challenges {
  @include vars.display-flex(column, center, center);
  @include vars.page-size;
  background: vars.$color-background-default;
  color: vars.$color-font-contrast;

  &__content {
    @include vars.display-flex(column, center, space-evenly);
    width: 100%;
    height: 80%;
  }

  &__description {
    width: 100%;

    h3 {
      background: vars.$color-background-primary;
      color: vars.$color-font-default;
      font-size: 60px;
      font-weight: vars.$font-weight-light;
      line-height: 75px;
      padding: 10px 50px 10px 150px;
      text-transform: uppercase;
      margin: 0;
      width: fit-content;
    }

    h4 {
      font-size: 45px;
      font-weight: vars.$font-weight-thin;
      line-height: 50px;
      margin: 10px 0 0 150px;
    }
  }

  &__list {
    margin: 0 150px;
    display: flex;

    .challenge {
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .acceptance {
    font-weight: vars.$font-weight-light;
    font-size: 35px;
    text-align: center;
  }
}

@media (min-width: vars.$device-mobile-large + 151) and (max-width: vars.$device-laptop) {
  .challenges {
    &__description {
      h3 {
        font-size: 45px;
        line-height: 50px;
      }

      h4 {
        font-size: 28px;
        line-height: 33px;
        font-weight: vars.$font-weight-light;
      }
    }

    &__list {
      flex-wrap: wrap;
      justify-content: space-evenly;

      .challenge {
        margin-right: 0;
        max-width: 30%;
      }
    }

    .acceptance {
      font-size: 28px;
      margin: 0;
    }
  }
}

@media (min-width: vars.$device-mobile-large + 151) and (max-width: vars.$device-tablet) {
  .challenges {
    &__description {
      h3 {
        padding: 5px 20px 5px 45px;
      }

      h4 {
        margin-left: 45px;
      }
    }

    &__list {
      margin: 0 45px;
    }
  }
}

@media (max-width: vars.$device-mobile-large + 150) {
  .challenges {
    &__content {
      height: 70%;
    }

    &__description {
      width: 80%;
      text-align: center;

      h3 {
        font-size: 35px;
        line-height: 35px;
        padding: 4px 0;
        width: 100%;
      }

      h4 {
        font-size: 18px;
        line-height: 25px;
        font-weight: vars.$font-weight-light;
        margin: 8px 0 0 0;
      }
    }

    &__list {
      width: 100%;
      height: 50%;

      .challenge {
        width: 70%;
      }
    }

    .acceptance {
      width: 60%;
      text-align: center;
      margin: 10px;
      font-size: 28px;
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 150) {
  .challenges {
    margin-top: 10px;

    &__content {
      height: 70%;
    }

    &__description {
      width: 80%;
      text-align: center;

      h3 {
        font-size: 25px;
        line-height: 30px;
        padding: 2px 0;
        width: 100%;
      }

      h4 {
        font-size: 16px;
        line-height: 25px;
        font-weight: vars.$font-weight-light;
        margin: 2px 0 0 0;
      }
    }

    &__list {
      width: 100%;
      height: 40%;

      .challenge {
        width: 70%;
        max-width: 70%;
      }
    }

    .acceptance {
      width: 50%;
      font-size: 21px;
      text-align: center;
      margin: 10px;

      br {
        display: none;
      }
    }
  }
}
