@use 'styles/variables' as vars;

.founders {
  @include vars.display-flex(column, center, center);
  @include vars.page-size;
  background: vars.$color-background-default;

  &__content {
    @include vars.display-flex(column, center, center);
    width: 100%;
    height: 100%;
    background: url('../../../assets/images/mini/meeting-min.jpg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 40%;

    h3 {
      font-size: 60px;
      font-weight: vars.$font-weight-light;
      color: vars.$color-font-default;
      text-transform: uppercase;
      position: relative;
      margin: 0 0 30px 0;

      &:after {
        content: '';
        height: 2px;
        width: 40%;
        background: vars.$color-background-primary;
        position: absolute;
        bottom: 0;
        left: 30%;
        right: 30%;
      }
    }
  }

  &__list {
    @include vars.display-flex(row, flex-start, space-evenly);
    width: 100%;

    .founder {
      padding: 2% 5%;
      width: 45%;
    }
  }
}

@media (max-width: vars.$device-laptop) {
  .founders {
    h3 {
      font-size: 25px;
      margin-bottom: 0;
    }

    &__list {
      @include vars.display-flex(row, flex-start, space-evenly);
      height: 75%;
      width: 100%;
      background-size: 100% 30%;

      .founder {
        width: 90%;
      }
    }
  }
}
