@use 'styles/variables' as vars;
@use 'styles/colors' as colors;

header {
  @include vars.display-flex(row, center, space-between);
  height: vars.$header-default-height;

  &.mobile-nav-active {
    &.light {
      animation: changeBackgroundToLight 0.5s linear forwards;
    }

    &.dark {
      animation: changeBackgroundToDark 0.5s linear forwards;
    }
  }

  img {
    max-height: 64px;
    margin-left: 50px;
  }

  .button {
    margin-right: 50px;
    width: fit-content;
    height: 44px;

    &__content {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}

@keyframes changeBackgroundToLight {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: colors.$color-background-mobile-nav-light;
  }
}

@keyframes changeBackgroundToDark {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: colors.$color-background-mobile-nav-dark;
  }
}

@media (max-width: vars.$device-tablet) {
  header {
    img {
      margin-left: 20px;
    }
  }
}

@media (min-width: vars.$device-mobile-large + 1) and (max-width: vars.$device-tablet) {
  header img {
    max-height: 60px;
  }
}

@media (max-width: vars.$device-mobile-large) {
  header {
    height: vars.$header-smaller-height;

    img {
      max-height: 40px;
    }
  }
}
