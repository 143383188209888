@use 'styles/variables' as vars;
@use 'styles/colors' as colors;

.home-sections-counter {
  @include vars.display-flex(column, center, center);
  margin-right: 50px;

  .home-section {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: colors.$color-bw-g6;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .active {
    background-color: vars.$color-background-primary;
  }
}

@media (max-width: vars.$device-tablet) {
  .home-sections-counter {
    flex-direction: row;
    margin-right: 0;
    margin-bottom: 35px;

    .home-section {
      margin-bottom: 0;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: vars.$device-mobile-large) {
  .home-sections-counter {
    margin-bottom: 25px;
  }
}

@media (max-width: vars.$device-mobile-small) {
  .home-sections-counter {
    margin-bottom: 15px;
  }
}
