@use 'styles/variables' as vars;

.company-scope {
  @include vars.page-size;
  background: vars.$color-background-default;
  color: vars.$color-font-contrast;
  display: flex;
  justify-content: center;

  &__content {
    @include vars.display-flex(row, center, center);
    @include vars.content-default-size;
    column-gap: 10px;

    h3 {
      font-size: 70px;
      font-weight: vars.$font-weight-thin;
      text-transform: uppercase;
      margin: 0;
    }

    .text-emphasis {
      font-weight: vars.$font-weight-medium;
    }

    img {
      width: 50%;
    }

    p {
      font-size: 18px;
      font-weight: vars.$font-weight-light;
      align-self: stretch;
      margin: 18px 0;
    }
  }
}

@media (min-width: vars.$device-mobile-large + 1) and (max-width: vars.$device-laptop) {
  .company-scope .company-scope__content {
    @include vars.content-tablet-size;
    align-items: center;

    h3 {
      font-size: 50px;
      text-align: center;
    }

    img {
      margin: 30px 0 45px 0;
    }

    p {
      text-align: center;
      width: 70%;
      align-self: center;
    }
  }
}

@media (max-width: vars.$device-laptop) {
  .company-scope .company-scope__content {
    flex-direction: column;

    img {
      width: 45%;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: vars.$font-weight-regular;
    }
  }
}

@media (max-width: vars.$device-tablet) {
  .company-scope .company-scope__content img {
    width: 70%;
  }
}

@media (max-width: vars.$device-mobile-large) {
  .company-scope .company-scope__content {
    @include vars.content-mobile-size;
    align-items: flex-start;

    h3 {
      font-size: 30px;
    }

    img {
      margin: 15px 0;
      width: 80%;
      align-self: center;
    }

    p {
      font-size: 14px;
    }
  }
}

@media (max-height: vars.$device-mobile-large + 1) and (orientation: landscape) {
  .company-scope .company-scope__content {
    @include vars.display-flex(row, center, center);

    h3 {
      font-size: 22px;
      text-align: left;
    }

    img {
      width: 70%;
    }

    p {
      font-size: 12px;
      text-align: left;
    }
  }
}
