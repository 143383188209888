.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;

  .slides {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    list-style: none;
    position: relative;
  }

  .slide {
    margin: 0;
    padding: 0;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s transform ease-in-out;

    &:nth-of-type(2) {
      left: 100%;
    }

    &:nth-of-type(3) {
      left: 200%;
    }

    &:nth-of-type(4) {
      left: 300%;
    }

    &:nth-of-type(5) {
      left: 400%;
    }

  }

  input[type="radio"] {
    display: none;

    &:nth-of-type(1):checked~.slide {
      transform: translatex(0%);
    }

    &:nth-of-type(2):checked~.slide {
      transform: translatex(-100%);
    }

    &:nth-of-type(3):checked~.slide {
      transform: translatex(-200%);
    }

    &:nth-of-type(4):checked~.slide {
      transform: translatex(-300%);
    }

    &:nth-of-type(5):checked~.slide {
      transform: translatex(-400%);
    }

    &:nth-of-type(1):checked~.navigator:nth-of-type(1) {
      display: flex;
    }

    &:nth-of-type(2):checked~.navigator:nth-of-type(2) {
      display: flex;
    }

    &:nth-of-type(3):checked~.navigator:nth-of-type(3) {
      display: flex;
    }

    &:nth-of-type(4):checked~.navigator:nth-of-type(4) {
      display: flex;
    }

    &:nth-of-type(5):checked~.navigator:nth-of-type(5) {
      display: flex;
    }

  }

  .navigator {
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    width: 100%;
    z-index: 5;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    display: none;

    img {
      cursor: pointer;
    }

  }

}
