@use './colors' as colors;

// default colors
$color-background-default: colors.$color-bw-white;
$color-background-primary: colors.$color-brand-gold;
$color-background-primary-alpha-95: colors.$color-brand-gold-alpha-95;
$color-background-secondary: colors.$color-brand-navy-blue;
$color-background-secondary-alpha-20: colors.$color-brand-navy-blue-alpha-20;
$color-font-default: colors.$color-bw-white;
$color-font-contrast: colors.$color-bw-black;
$color-font-primary: colors.$color-brand-gold;
$color-font-secondary: colors.$color-brand-navy-blue;

// font weights
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// default page width and height
$width-page-default: 100%;
$height-page-default: 100vh;

// devices width
$device-mobile-large: 430px;
$device-mobile-medium: 375px;
$device-mobile-small: 320px;
$device-tablet: 768px;
$device-laptop: 1024px;

// components height
$header-default-height: 100px;
$header-smaller-height: 75px;

// mixins
@mixin display-flex($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin page-size {
  width: $width-page-default;
  height: $height-page-default;
}

@mixin content-default-size {
  width: $width-page-default;
  margin: $header-default-height 150px;
}

@mixin content-tablet-size {
  width: $width-page-default;
  margin: $header-default-height 45px;
}

@mixin content-mobile-size {
  width: $width-page-default;
  margin: $header-smaller-height 15px;
}
