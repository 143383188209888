@use 'styles/variables' as vars;

.challenge_card {
  background: vars.$color-background-default;

  img {
    width: 40px;
    height: 40px;
  }

  &-texts {
    margin: 10px 0 0 10px;
    color: vars.$color-font-contrast;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -10px;
      width: 2px;
      height: 124px;
      background: vars.$color-background-primary;
    }

    h5 {
      margin: 0;
      font-size: 18px;
      text-transform: uppercase;
    }

    p {
      margin: 0;
      font-size: 18px;
      font-weight: vars.$font-weight-light;
    }
  }
}

@media (max-width: vars.$device-tablet) {
  .challenge_card .challenge_card-texts {
    h5,
    p {
      font-size: 18px;
    }

    &:before {
      height: 100px;
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 150) {
  .challenge_card .challenge_card-texts {
    h5,
    p {
      font-size: 16px;
    }
  }
}
