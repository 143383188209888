@use 'styles/variables' as vars;

.founder_card {
  @include vars.display-flex(column, center, center);
  width: 100%;
  color: vars.$color-font-contrast;

  .rounded-image {
    width: 160px;
  }

  .infos {
    margin-top: 10px;

    .profile {
      @include vars.display-flex(row, center, center);
      text-decoration: none;

      .linkedin-icon {
        width: 30px;
        height: 30px;
      }

      h4 {
        font-size: 48px;
        font-weight: vars.$font-weight-thin;
        text-align: center;
        color: vars.$color-font-contrast;
        margin: 0 10px 0 0;
      }
    }

    .role {
      font-size: 20px;
      line-height: 29px;
      font-weight: vars.$font-weight-extra-bold;
      margin: 0;
      text-transform: uppercase;
      text-align: center;
    }

    .roleDescription {
      font-size: 16px;
      line-height: 23px;
      font-weight: vars.$font-weight-medium;
      margin: 0;
      text-transform: uppercase;
      text-align: center;
    }

    .description {
      font-size: 16px;
      font-weight: vars.$font-weight-light;
      margin: 15px 0;
      text-align: center;
    }

    .quote {
      font-size: 18px;
      font-weight: vars.$font-weight-light;
      margin: 0 0 0 10px;
      position: relative;

      &:before {
        content: '';
        background: vars.$color-background-primary;
        position: absolute;
        width: 2px;
        height: 100%;
        left: -10px;
        display: block;
      }
    }
  }
}

@media (max-width: vars.$device-laptop) {
  .founder_card {
    flex-direction: column;
    align-items: center;

    .infos {
      @include vars.display-flex(column, center, center);

      .description {
        font-weight: vars.$font-weight-regular;
        text-align: center;
        margin-bottom: 0;
      }

      .quote {
        font-weight: vars.$font-weight-light;
        text-align: center;
        margin-left: 0;
        margin-top: 22px;
        position: relative;

        &:before {
          width: 54px;
          height: 2px;
          top: -10px;
          display: block;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
  }
}

@media (min-width: vars.$device-mobile-large + 1) and (max-width: vars.$device-laptop) {
  .founder_card {
    .rounded-image {
      width: 180px;
    }

    .infos {
      .description {
        margin-top: 20px;
      }

      .quote {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: vars.$device-mobile-medium + 1) and (max-width: vars.$device-mobile-large) {
  .founder_card {
    .infos {
      .profile {
        .linkedin-icon {
          width: 24px;
          height: 24px;
        }

        h4 {
          font-size: 35px;
        }
      }

      h5 {
        font-size: 10px;
        font-weight: vars.$font-weight-extra-bold;
      }

      .description {
        font-size: 14px;
        margin-top: 20px;
      }

      .quote {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: vars.$device-mobile-small + 1) and (max-width: vars.$device-mobile-medium) {
  .founder_card {
    .infos {
      .profile {
        .linkedin-icon {
          width: 22px;
          height: 22px;
        }

        h4 {
          font-size: 32px;
        }
      }

      h5 {
        font-size: 10px;
        font-weight: vars.$font-weight-extra-bold;
      }

      .description {
        font-size: 14px;
        margin-top: 15px;
      }

      .quote {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: vars.$device-mobile-small) {
  .founder_card {
    .infos {
      .profile {
        .linkedin-icon {
          width: 20px;
          height: 20px;
        }

        h4 {
          font-size: 30px;
        }
      }

      h5 {
        font-size: 10px;
        font-weight: vars.$font-weight-extra-bold;
      }

      .description {
        font-size: 12px;
        margin-top: 10px;
      }

      .quote {
        font-size: 14px;
      }
    }
  }
}

@media (orientation: landscape) and (max-height: 530px) {
  .founder_card {
    .rounded-image {
      width: 140px;
    }

    .infos {
      .profile {
        .linkedin-icon {
          width: 20px;
          height: 20px;
        }

        h4 {
          font-size: 35px;
        }
      }

      h5 {
        font-size: 10px;
        font-weight: vars.$font-weight-extra-bold;
      }

      .description {
        font-size: 12px;
        margin-top: 10px;
      }

      .quote {
        font-size: 14px;
      }
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 1) {
  .founder_card {
    .rounded-image {
      width: 100px;
    }

    .infos {
      .profile {
        .linkedin-icon {
          width: 20px;
          height: 20px;
        }

        h4 {
          font-size: 25px;
        }
      }

      h5 {
        font-size: 10px;
        font-weight: vars.$font-weight-extra-bold;
      }

      .description {
        font-size: 12px;
        margin-top: 10px;
      }

      .quote {
        font-size: 14px;
      }
    }
  }
}
