@use 'styles/variables' as vars;

.mission {
  @include vars.page-size;
  display: flex;
  align-items: center;
  background: vars.$color-background-default;
  color: vars.$color-font-default;

  &__content {
    @include vars.display-flex(column, center, center);
    height: 60%;
    width: 100%;
    margin: vars.$header-default-height 0;
    background: url('../../../assets/images/mini/tunnel-mini.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;

    h3 {
      margin: 0;
      font-size: 35px;
      font-weight: vars.$font-weight-light;
      text-transform: uppercase;
      position: relative;

      .text-emphasis {
        font-size: 75px;
        font-weight: vars.$font-weight-thin;
      }

      &:after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: -10px;
        right: 30%;
        left: 30%;
        width: 40%;
        background: vars.$color-background-primary;
      }
    }

    .opportunities {
      margin: 48px 0 0 0;
      font-size: 28px;
      line-height: 55px;
      font-weight: vars.$font-weight-light;

      .text-emphasis {
        font-weight: vars.$font-weight-medium;
      }
    }

    .process {
      margin: 0;
      font-size: 18px;
      font-weight: vars.$font-weight-bold;
    }

    .button {
      margin-top: 32px;

      &__content {
        height: unset;
      }
    }
  }
}

@media (min-width: vars.$device-mobile-large + 161) and (max-width: vars.$device-laptop) {
  .mission .mission__content {
    height: 65%;
    padding: 0 20%;

    h3 {
      font-size: 18px;
      line-height: 25px;

      .text-emphasis {
        font-size: 60px;
        line-height: 65px;
      }

      &:after {
        width: 50%;
        bottom: -25px;
        right: 25%;
        left: 25%;
      }
    }

    .opportunities {
      margin-top: 52px;
      font-size: 21px;
      line-height: 25px;
    }

    .process {
      margin: 25px;
      font-size: 16px;
    }
  }
}

@media (max-width: vars.$device-mobile-large + 160) {
  .mission .mission__content {
    height: 65%;
    padding: 35px 10%;
    margin: vars.$header-smaller-height 0;

    h3 {
      font-size: 18px;
      line-height: 20px;

      .text-emphasis {
        font-size: 45px;
        line-height: 45px;
        margin-top: 15px;
      }

      &:after {
        width: 60%;
        bottom: -25px;
        right: 20%;
        left: 20%;
      }
    }

    .opportunities {
      margin-top: 52px;
      font-size: 21px;
      line-height: 25px;
    }

    .process {
      margin: 25px 25px 0 25px;
      font-size: 14px;
    }

    .button {
      margin-top: 28px;
    }
  }
}

@media (max-width: vars.$device-mobile-small) {
  .mission .mission__content {
    height: 60%;
    padding-left: 5%;
    padding-right: 5%;

    h3:after {
      bottom: -15px;
    }

    .opportunities {
      margin-top: 30px;
    }

    .process {
      margin-top: 15px;
    }

    .button {
      margin-top: 15px;
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 1) {
  .mission .mission__content {
    height: 60%;
    padding-left: 5%;
    padding-right: 5%;

    h3 {
      .text-emphasis {
        font-size: 35px;
        line-height: 35px;
      }
    }

    h3:after {
      bottom: -15px;
    }

    .opportunities {
      margin-top: 30px;
    }

    .process {
      margin: 5px;
    }

    .button {
      margin-top: 5px;
    }
  }
}
