@use 'styles/variables' as vars;

.companies {
  @include vars.display-flex(column, center, center);
  @include vars.page-size;
  background: url('../../../assets/images/gradient-midnight-blue.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: vars.$color-font-default;
  text-align: center;

  &__content {
    @include vars.display-flex(column, center, space-evenly);
    height: 100%;
    margin: vars.$header-default-height 150px;

    .names {
      @include vars.display-flex(row, center, center);
      flex-wrap: wrap;

      p {
        margin: 8px 24px;
        font-size: 33px;
        font-weight: vars.$font-weight-thin;
        text-align: center;
        opacity: 0.4;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    h3 {
      margin: 30px 0;
      font-size: 45px;
      font-weight: vars.$font-weight-thin;
      text-transform: uppercase;

      b {
        font-size: 50px;
        font-weight: vars.$font-weight-medium;
        margin: 0 10px;
      }

      .text-emphasis {
        font-size: 60px;
        font-weight: vars.$font-weight-light;

        .highlight {
          background: vars.$color-background-primary;
          font-weight: vars.$font-weight-bold;
          padding: 0 15px;
        }
      }
    }
  }
}

@media (max-width: 1368px) or (max-height: vars.$device-laptop) {
  .companies .companies__content {
    margin: vars.$header-smaller-height 25px;

    .names p {
      font-size: 22px;
      margin: 10px 13px;
    }

    h3 {
      font-size: 28px;
      margin: 10px 0;

      b {
        font-size: 28px;
        font-weight: vars.$font-weight-medium;
      }

      .text-emphasis {
        font-size: 60px;
        line-height: 65px;

        .highlight {
          display: inline-block;
          padding: 5px;
        }
      }
    }
  }
}

// all mobile devices
@media (max-width: vars.$device-tablet) {
  .companies .companies__content {
    margin: vars.$header-smaller-height 15px;

    .names p {
      font-size: 14px;
      margin: 5px;
    }

    h3 {
      font-size: 18px;
      margin: 10px 0;

      b {
        font-size: 18px;
        font-weight: vars.$font-weight-medium;
        margin: 0 5px;
      }

      .text-emphasis {
        font-size: 45px;
        line-height: 50px;

        .highlight {
          display: inline-block;
          padding: 5px;
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 1) {
  .companies .companies__content {
    margin: vars.$header-smaller-height 15px 50px 15px;

    .names p {
      font-size: 12px;
      margin: 2px 6px;
    }

    h3 {
      font-size: 16px;
      margin: 5px 0;

      b {
        font-size: 16px;
        font-weight: vars.$font-weight-medium;
      }

      .text-emphasis {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }
}
