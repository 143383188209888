@use 'styles/variables' as vars;
@use 'styles/colors' as colors;

@mixin dot-size($class-name, $size) {
  .#{$class-name} {
    width: #{$size};
    height: #{$size};
  }
}

.dot-indicator {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);

  @include dot-size('size0', 12px);
  @include dot-size('size1', 8px);
  @include dot-size('size2', 7px);
  @include dot-size('size3', 6px);
  @include dot-size('size4', 5px);
  @include dot-size('size5', 4px);

  .dot {
    @include dot-size('size5', 4px);
    border-radius: 50%;
    background-color: colors.$color-bw-g6;
  }

  .active {
    background-color: colors.$color-brand-gold;
  }
}

@media (orientation: portrait) {
  .dot-indicator {
    flex-direction: row;
    right: unset;
    top: unset;
    bottom: 27px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large) {
  .dot-indicator {
    display: none;
  }
}
