@use 'styles/variables' as vars;

.differential_card {
  color: vars.$color-font-default;

  img {
    width: 40px;
    height: 40px;
  }

  &-texts {
    margin: 10px 0 0 10px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -10px;
      width: 2px;
      height: 124px;
      background: vars.$color-background-primary;
    }

    h5 {
      font-size: 28px;
      font-weight: vars.$font-weight-medium;
      margin: 0;
    }

    p {
      font-size: 21px;
      font-weight: vars.$font-weight-light;
      margin: 5px 0 0 0;
    }
  }
}

@media (max-width: vars.$device-tablet) {
  .differential_card .differential_card-texts {
    margin-top: 5px;

    &:before {
      height: 100%;
    }

    h5 {
      font-size: 18px;
      font-weight: vars.$font-weight-bold;
    }

    p {
      font-size: 16px;
    }
  }
}

@media (orientation: landscape) and (max-height: vars.$device-mobile-large + 1) {
  .differential {
    width: 85%;
    margin-right: 0;

    .differential_card .differential_card-texts {
      margin: 0 0 0 10px;

      h5 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
        margin: 5px 0 0 0;
      }
    }
  }
}
